<template>
  <el-main>
    <div class="main">
      <h2 class="title">个人信息</h2>
      <div class="personal-form">
        <el-form
          :model="ruleForm"
          status-icon
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm"
        >
          <el-form-item>
            <el-button type="primary" @click="syncuser()">
              同步信息
            </el-button>
          </el-form-item>
          <el-form-item label="用户名：" prop="nick_name">
            <!--                  <el-input-->
            <!--                    type="password"-->
            <!--                    v-model="ruleForm.pass"-->
            <!--                    autocomplete="off"-->
            <!--                  ></el-input>-->
            {{ nick_name }}
          </el-form-item>
          <el-form-item label="真实姓名：" prop="pass">
            <!--                  <el-input-->
            <!--                    type="password"-->
            <!--                    v-model="ruleForm.pass"-->
            <!--                    autocomplete="off"-->
            <!--                  ></el-input>-->
            {{ real_name }}
          </el-form-item>
          <el-form-item label="性别：" prop="pass">
            <!--                  <el-radio v-model="radio" label="1">男</el-radio>-->
            <!--                  <el-radio v-model="radio" label="2">女</el-radio>-->
            {{ sex }}
          </el-form-item>
          <el-form-item label="身份证号码：" prop="pass">
            <!--                  <el-input-->
            <!--                    type="password"-->
            <!--                    v-model="ruleForm.pass"-->
            <!--                    autocomplete="off"-->
            <!--                  ></el-input>-->
            {{ idcard }}
          </el-form-item>
          <el-form-item label="课程类型：" prop="pass">
            <!--                  <el-select v-model="value" placeholder="请选择">-->
            <!--                    <el-option-->
            <!--                      v-for="item in options"-->
            <!--                      :key="item.value"-->
            <!--                      :label="item.label"-->
            <!--                      :value="item.value"-->
            <!--                    >-->
            <!--                    </el-option>-->
            <!--                  </el-select>-->
            {{ licence_title }}
          </el-form-item>
          <el-form-item label="手机号码：" prop="checkPass">
            <!--                  <el-input-->
            <!--                    type="password"-->
            <!--                    v-model="ruleForm.checkPass"-->
            <!--                    autocomplete="off"-->
            <!--                  ></el-input>-->
            {{ phone }}
          </el-form-item>
          <el-form-item label="驾校地址：" prop="age">
            <!--                  <el-input v-model.number="ruleForm.age"></el-input>-->
            {{ jiaxiao_address }}
          </el-form-item>
          <el-form-item label="驾校名称：" prop="age">
            <!--                  <el-input v-model.number="ruleForm.age"></el-input>-->
            {{ jiaxiao_name }}
          </el-form-item>
        </el-form>
      </div>
    </div>
  </el-main>
</template>
<script>
import { getResult, getLicenceName } from '../utils/auth'
import { syncuser } from '../api/user'

export default {
  components: {},

  data() {
    var checkAge = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('年龄不能为空'))
      }
      setTimeout(() => {
        if (!Number.isInteger(value)) {
          callback(new Error('请输入数字值'))
        } else {
          if (value < 18) {
            callback(new Error('必须年满18岁'))
          } else {
            callback()
          }
        }
      }, 1000)
    }
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'))
      } else {
        if (this.ruleForm.checkPass !== '') {
          this.$refs.ruleForm.validateField('checkPass')
        }
        callback()
      }
    }
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.ruleForm.pass) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    return {
      avatar: '',
      real_name: '',
      licence_title: '',
      licence_name: '',
      jiaxiao_name: '',
      ruleForm: {
        pass: '',
        checkPass: '',
        age: ''
      },
      options: [
        {
          value: '选项1',
          label: '黄金糕'
        },
        {
          value: '选项2',
          label: '双皮奶'
        },
        {
          value: '选项3',
          label: '蚵仔煎'
        },
        {
          value: '选项4',
          label: '龙须面'
        },
        {
          value: '选项5',
          label: '北京烤鸭'
        }
      ],
      dialogFormVisible: false,
      formLabelWidth: '120px',
      form: {
        name: '',
        region: '',
        date1: '',
        date2: '',
        delivery: false,
        type: [],
        resource: '',
        desc: ''
      },
      value: '',
      radio: '',
      rules: {
        pass: [{ validator: validatePass, trigger: 'blur' }],
        checkPass: [{ validator: validatePass2, trigger: 'blur' }],
        age: [{ validator: checkAge, trigger: 'blur' }]
      }
    }
  },
  created() {
    this.licence_name = getLicenceName()
    this.getInfo()
  },
  methods: {
    //用户信息
    getInfo() {
      this.nick_name = JSON.parse(getResult()).nick_name
      this.idcard = JSON.parse(getResult()).idcard
      this.phone = JSON.parse(getResult()).phone
      this.avatar = JSON.parse(getResult()).avatar
      this.real_name = JSON.parse(getResult()).real_name
      this.licence_title = JSON.parse(getResult()).licence_title
      this.jiaxiao_name = JSON.parse(getResult()).jiaxiao_name
      this.jiaxiao_address = JSON.parse(getResult()).jiaxiao_address
      if (JSON.parse(getResult()).sex === 0) {
        this.sex = '未知'
      } else if (JSON.parse(getResult()).sex === 1) {
        this.sex = '男'
      } else if (JSON.parse(getResult()).sex === 2) {
        this.sex = '女'
      }
    },
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          alert('submit!')
        } else {
          // console.log('error submit!!')
          return false
        }
      })
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
    },
    //学员信息同步
    syncuser() {
      syncuser().then(res => {
        if (res.code === 0) {
          this.$message({
            message: '信息同步成功！',
            type: 'success'
          })
        } else {
          this.$message({
            message: '信息同步失败！',
            type: 'error'
          })
        }
      })
    }
  }
}
</script>
